body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #0366d6;
}

a:hover {
  text-decoration: underline;
}

header {
  background-color: #24292e;
}

header .container {
  display: flex;
  max-width: 1012px;
  height: 42px;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

header ul li {
  float: left;
  padding-left: 32px;
}

header ul {
  display: flex;
  list-style: none;
  font-size: 14px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  align-items: center;
}

header ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-align: center;
}

header ul li a:hover {
  color: antiquewhite
}

main .container {
  max-width: 1012px;
  margin-left: auto;
  margin-right: auto;
}
.App {
  text-align: center;
  min-height: 100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

